var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"section"},[_c('v-sheet',{staticClass:"overflow-y-auto",attrs:{"id":"scrolling-techniques-7","max-height":"90vh"}},[_c('v-container',{staticClass:"mt-10",staticStyle:{"max-height":"65vh"}},[_c('SchedulesFilter',{on:{"setQuery":_vm.getScheduleList},model:{value:(_vm.isFiltersActive),callback:function ($$v) {_vm.isFiltersActive=$$v},expression:"isFiltersActive"}}),_c('div',{staticClass:"mt-8",attrs:{"id":"user-list"}},[_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"user-search me-3 mb-4",attrs:{"expanded":"","placeholder":_vm.$t('Search'),"outlined":"","hide-details":"","dense":"","prepend-inner-icon":_vm.icons.mdiMagnify},on:{"change":function($event){return _vm.searchSchedules()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{attrs:{"outlined":"","color":"grey-light"},on:{"click":function($event){$event.stopPropagation();_vm.isFiltersActive = !_vm.isFiltersActive}}},[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilterVariant)+" ")])],1)],1),_c('v-card',{staticClass:"mt-8"},[[_c('v-data-table',{attrs:{"show-select":"","calculate-widths":"","items-per-page":25,"footer-props":{
                                'items-per-page-options': [25],
                                'items-per-page-text': _vm.$t('RowsPerPage'),
                            },"header-props":{ 'sort-by-text': _vm.$t('SortBy') },"headers":_vm.tableColumns,"items":_vm.scheduleListTable,"server-items-length":_vm.totalScheduleListTable,"loading":_vm.isLoading,"options":_vm.options,"height":"400","fixed-header":""},on:{"update:options":function($event){_vm.options=$event},"update:page":_vm.paginationHandle},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"extend-column d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{
                                            name: 'StopTimesProfile',
                                            params: { id: item.id, goToSchedule: true },
                                        }}},[_vm._v(" "+_vm._s(item.id)+" ")])],1)]}},{key:"item.stop.name",fn:function(ref){
                                        var item = ref.item;
return [_c('div',{staticClass:"extend-column d-flex flex-column ms-3"},[_c('span',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(item.stop.name)+" "),(item.stop.city)?_c('span',[_vm._v("("+_vm._s(item.stop.city)+")")]):_vm._e()])])]}},{key:"item.actions",fn:function(ref){
                                        var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({
                                            name: 'StopTimesProfile',
                                            params: { id: item.id, goToSchedule: true },
                                        })}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(_vm.icons.mdiChevronRight))])],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})]],2)],1),_c('v-snackbar',{attrs:{"timeout":2000,"top":"","color":"white"},model:{value:(_vm.isSuccess),callback:function ($$v) {_vm.isSuccess=$$v},expression:"isSuccess"}},[_c('v-img',{staticClass:"mx-auto trashcan",attrs:{"width":"80","src":require("@/assets/icons/correct.png")}}),_c('p',{staticClass:"text-center title font-weight-semibold black--text mt-5"},[_vm._v(_vm._s(_vm.$t('Alerts.Success')))]),_c('p',{staticClass:"text-center black--text"},[_vm._v(_vm._s(_vm.$t('Alerts.DeleteSuccess')))])],1),_c('v-snackbar',{attrs:{"timeout":2000,"top":"","color":"white"},model:{value:(_vm.isError),callback:function ($$v) {_vm.isError=$$v},expression:"isError"}},[_c('v-img',{staticClass:"mx-auto trashcan",attrs:{"width":"80","src":require("@/assets/icons/failed.png")}}),_c('p',{staticClass:"text-center title font-weight-semibold black--text mt-5"},[_vm._v(_vm._s(_vm.$t('Alerts.Error')))]),_c('p',{staticClass:"text-center black--text"},[_vm._v(_vm._s(_vm.$t('Alerts.ErrorMessage')))])],1)],1)],1),_c('v-app-bar',{attrs:{"height":"80","absolute":"","color":"white","elevate-on-scroll":"","scroll-target":"#scrolling-techniques-7"}},[_c('v-container',{staticClass:"d-flex"},[_c('v-toolbar-title',[_c('p',{staticClass:"d-none d-md-flex d-lg-flex d-xl-flex text-h5 font-weight-semibold black--text mt-1"},[_vm._v(" "+_vm._s(_vm.$t('SchedulesList'))+" ")]),_c('p',{staticClass:"text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none"},[_vm._v(" "+_vm._s(_vm.$t('SchedulesList'))+" ")])]),_c('v-spacer'),(_vm.selected.length > 0)?_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
                                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"error"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2 d-none d-md-flex d-lg-flex d-xl-flex",attrs:{"color":"error"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCanOutline)+" ")]),_c('v-icon',{staticClass:"d-flex d-xs-flex d-sm-flex d-md-none",attrs:{"color":"error"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCanOutline)+" ")]),_c('span',{staticClass:"d-none d-md-flex d-lg-flex d-xl-flex"},[_vm._v(_vm._s(_vm.$t('Buttons.Delete')))])],1)]}},{key:"default",fn:function(dialog){return [_c('v-img',{staticClass:"mx-auto trashcan",attrs:{"width":"80","src":require("@/assets/icons/delete.png")}}),_c('v-card',[_c('v-card-text',{staticClass:"text-center"},[_c('p',{staticClass:"title font-weight-semibold black--text mt-12"},[_vm._v(_vm._s(_vm.$t('Alerts.Sure')))])]),_c('v-card-text',{staticClass:"text-center"},[(_vm.selected.length === 1)?_c('p',{staticClass:"body-2"},[_vm._v(" "+_vm._s(_vm.$t('Alerts.SureDeleteStopTime'))+" "),_c('strong',{staticClass:"black--text"},[_vm._v(_vm._s(_vm.selected.map(function (entry) { return entry.id; }).join(', ')))]),_vm._v(" ?"),_c('br'),_vm._v(" "+_vm._s(_vm.$t('Alerts.Irreversible'))+" ")]):_c('p',{staticClass:"body-2"},[_vm._v(" "+_vm._s(_vm.$t('Alerts.SureDeleteStopTime'))+" "),_c('strong',{staticClass:"black--text"},[_vm._v(_vm._s(_vm.selected.map(function (entry) { return entry.id; }).join(', ')))]),_vm._v(" ?"),_c('br'),_vm._v(" "+_vm._s(_vm.$t('Alerts.Irreversible'))+" ")])]),_c('v-card-actions',{staticClass:"justify-end mt-4"},[_c('v-btn',{staticClass:"px-5",attrs:{"color":"error"},on:{"click":_vm.deleteSelectedSchedules}},[_vm._v(_vm._s(_vm.$t('Buttons.Delete')))]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v(_vm._s(_vm.$t('Buttons.Cancel')))])],1)],1)]}}],null,false,3119530091)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }