import store from '@/store';
import _ from 'lodash';
import { i18n } from '@/plugins/i18n';
import { ref, watch } from '@vue/composition-api';

export default function useRoutesList() {
    const scheduleListTable = ref([]);

    const tableColumns = [
        { text: i18n.t('ScheduleID'), value: 'id', class: 'text-uppercase', width: '3rem' },
        { text: i18n.t('Arrival'), value: 'arrival_time', class: 'text-uppercase', width: '6rem' },
        { text: i18n.t('Departure'), value: 'departure_time', class: 'text-uppercase', width: '6rem' },
        { text: i18n.t('Route'), value: 'trip.route.long_name', class: 'text-uppercase', width: '12rem' },
        { text: i18n.t('Stop'), value: 'stop.name', class: 'text-uppercase', width: '12rem' },
        { text: i18n.t('Transport'), value: 'trip.transport.name', class: 'text-uppercase', width: '12rem' },

        {
            text: '',
            value: 'actions',
            width: '5%',
        },
    ];

    const totalScheduleListTable = scheduleListTable.length;
    const oldSearch = ref('');
    const oldDepartureFilter = ref('');
    const oldStopFilter = ref('');
    const oldRouteFilter = ref('');
    const oldArrivalFilter = ref('');

    const fetchSchedulesList = () => {
        let verifyQuery = store.getters['app-schedules/getSchedulesQuery'];
        let deletedSchedule = store.getters['app-schedules/getIsDeleteSchedule'];

        store
            .dispatch('app-schedules/fetchScheduleList', {})
            .then((response) => {
                if (deletedSchedule) {
                    scheduleListTable.value = [];
                    scheduleListTable.value.push(...response.data.data);
                    store.commit('app-schedules/setIsDeleteSchedule', false);
                } else if (
                    oldSearch.value == verifyQuery.filters.$or[0].stop.name.$containsi &&
                    oldDepartureFilter.value == verifyQuery.filters.departure_time.$containsi &&
                    oldStopFilter.value == verifyQuery.filters.stop.id.$containsi &&
                    oldRouteFilter.value == verifyQuery.filters.trip.route.id.$containsi &&
                    oldArrivalFilter.value == verifyQuery.filters.arrival_time.$containsi
                ) {
                    scheduleListTable.value.push(...response.data.data);
                    scheduleListTable.value = _.uniqBy(scheduleListTable.value, 'id');
                } else {
                    oldSearch.value = verifyQuery.filters.$or[0].stop.name.$containsi;
                    oldDepartureFilter.value = verifyQuery.filters.departure_time.$containsi;
                    oldStopFilter.value = verifyQuery.filters.stop.id.$containsi;
                    oldRouteFilter.value = verifyQuery.filters.trip.route.id.$containsi;
                    oldArrivalFilter.value = verifyQuery.filters.arrival_time.$containsi;

                    scheduleListTable.value = [];
                    scheduleListTable.value.push(...response.data.data);

                    //remove duplicate from array
                    scheduleListTable.value = _.uniqBy(scheduleListTable.value, 'id');
                }

                totalScheduleListTable.value = response.data.meta.pagination.total;
                // parkTotalLocal.value = parkTotal;1

                // remove loading state
                loading.value = false;
            })
            .catch((error) => {
                throw error;
            });
    };

    return {
        tableColumns,
        scheduleListTable,
        totalScheduleListTable,
        fetchSchedulesList,
    };
}
